// status is copy, link or reject
export const approveTaskPublish = async (taskId, workspaceId, status, recursive) => {
    recursive = recursive || true;
    status = status || "reject";
    return await logFetchAJAX(getUrl(`/api/tasks/${taskId}/actions:approve_publish`), {
      method: "POST",
      body: {
        "workspace_id": workspaceId,
        "recursive": recursive,
        "status": status,
      }
    })
}
